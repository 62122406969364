import React from 'react'
import { Row, Col, Button, MediaBox } from 'react-materialize';
import NavBarMenu from '../../../NavBarMenu/NavBarMenu'
import 'materialize-css/dist/css/materialize.min.css';
import LogoMobile from '../../../../assets/logo/logo_mobile.svg'
import 'materialize-css';
import teamImg from '../../../../assets/img/team-about-us.jpg'

const About = () => {
    return (
        <div>
        <Row>
            <Col l={8} m={6} s={12} className="right-section">
                <div>            
                <NavBarMenu logo={LogoMobile}></NavBarMenu>
                </div>
            </Col> 
            <Col l={4} m={6} s={12}>
            <div className="mobile-page">
                <h4 className="blue-title">About Us</h4>
                <p>
                <strong>Dr. Alessandro Gasbarrini</strong> is the chief department of Oncologic and Degenerative Spine Surgery
                of <strong>Rizzoli Orthopedic Institute</strong>. His experience in the treatment of primary and metastatic bone
                tumors started at the beginning of Nineties during his residency training under the guidance of
                Prof. Mario Campanacci. In those years he, also, began to focus his interests in spine, which 
                later developed at Maggiore Hospital from 1998 to 2009 at the structure directed by Dr Stefano 
                Boriani becoming a referral center for the treatment primary and metastatic tumors of the spine. 
                Since September 2009 he works at Department of Oncologic and Degenerative Spine Surgery of <strong>Rizzoli Orthopedic Institute</strong>. 
                <strong> Dr. Gasbarrini</strong> has contributed and continues to contribute as author or co-author to numerous
                scientific papers published on national and international journals.
                </p>
                    <a href="http://www.ior.it/en/curarsi-al-rizzoli/alessandro-gasbarrini-md" rel="noopener noreferrer" target="_blank">
                    <Button className="complete-flowchart" waves="light" small >
                    Read complete CV of Dr. Alessandro Gasbarrini (italian)
                    </Button>
                </a>
                <MediaBox style={{ marginTop: "15px" }}>
                    <img src={teamImg} width="100%" alt="the team" />
                </MediaBox>
                <p>
                Dr. Alessandro Gasbarrini together with his team.
                </p>
            </div>
            </Col>
        </Row>
        </div>
    )
}

export default About

import React from 'react'
import { Row, Col } from 'react-materialize';
import NavBarMenu from '../../../NavBarMenu/NavBarMenu'
import LogoMobile from '../../../../assets/logo/logo_mobile.svg'
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';

const Bibiliography = () => {
    return (
        <div>
            <Row>
                <Col l={8} m={6} s={12} className="right-section">
                <div>            
                    <NavBarMenu logo={LogoMobile}></NavBarMenu>   
                </div>
                </Col> 
                <Col l={4} m={6} s={12}>
                    <div className="mobile-page">
                    <h4 className="blue-title">Bibiliography</h4>
                    
                    {/* <p>Gasbarrini A, Cappuccio M, Mirabile L, Bandiera S, Terzi S, Barbanti Bròdano G, Boriani S. <strong>Spinal metastases: treatment 
                    evaluation algorithm. Eur Rev Med Pharmac Sci</strong> (2004)</p>

                    <p>Ibrahim A, Crockard A, Antonietti P, Boriani S, Bünger C, Gasbarrini A, Grejs A, Harms J, Kawahara N, Mazel C, Melcher R, 
                    Tomita K. <strong>Does spinal surgery improve the quality of life for those with extradural (spinal) osseous metastases? 
                    An international multicenter prospective observational study of 223 patients. Invited submission from the Joint Section Meeting 
                    on Disorders of the Spine and Peripheral Nerves, March 2007.</strong> J Neurosurg Spine (2008)</p>

                    <p>Gasbarrini A, Boriani S. Linee Guida SIOT. <strong>Il trattamento delle metastasi ossee.</strong> (2008)</p>

                    <p>Gasbarrini A, Li H, Cappuccio M, Mirabile L, Paderni S, Terzi S, Boriani S. <strong>Efficacy evaluation of a new treatment algorithm 
                    for spinal metastases.</strong> Spine (2010)</p> */}

                    <p>
                        Gasbarrini A, Cappuccio M, Mirabile L, Bandiera S, Terzi S, Barbanti Bròdano G, Boriani S. 
                        <strong> Spinal Metastases: treatment evaluation algorithm.</strong> Eur Rev for Med Pharmacol Sci. 2004; 
                        8:265- 274.
                    </p>

                    <p>
                        Boriani S, Gasbarrini A. <strong> The Tokuhashi score: Significant predictive value for the life 
                        expectancy of patients with breast cancer with spinal metastases.</strong> Point of View. 
                        Spine 2005; 30: 2227- 2229.
                    </p>

                    <p>
                        Barbanti Brodano G, Cappuccio M, Gasbarrini A, Bandiera S, De Salvo F, Cosco F, Boriani S. 
                        <strong> Vertebroplasty in the treatment of vertebral metastases: clinical cases and review of the 
                        literature.</strong> Eur Rev for Med Pharmacol Sci. 2007; 11: 91- 100.
                    </p>

                    <p>
                        Cappuccio M, Gasbarrini A, Van Urk P, Bandiera S, Boriani S. <strong> Spinal metastasis: a 
                        retrospctive study validating the treatment algorithm.</strong> Eur Rev Med Pharmacol Sci. 2008; 
                        12: 155- 160.
                    </p>

                    <p>
                        Ibrahim A, Crockard A, Antonietti P, Boriani S, Bünger C, Gasbarrini A, Grejs A, Harms J, 
                        Kawahara N, Mazel C, Melcher R, Tomita K. <strong> Does spinal surgery improve the quality of life 
                        for those with extradural (spinal) osseous metastases? An international multicenter 
                        prospective observational study of 223 patients.</strong> J Neurosurg Spine 2008; 8: 271-278. 
                    </p>

                    <p>
                        Li H, Gasbarrini A, Cappuccio M, Terzi S, Paderni S, Mirabile L, Boriani S. 
                        <strong> Outcome of excisional surgeries for the patients with spinal metastases.</strong> 
                        Eur Spine J 2009; 18: 1423-1430.
                    </p>

                    <p>
                        Gasbarrini A, Li H, Cappuccio M, Mirabile L, Paderni S, Terzi S, Boriani S. 
                        <strong> Efficacy evalutation of a new treatment algorithm for spinal metastases.</strong> 
                        Spine 2010; 35: 1466-1470.
                    </p>

                    <p>
                        Cappuccio M, Bandiera S, Babbi L, Boriani L, Corgi A, Amendola L, Colangeli S, Terzi S, 
                        Gasbarrini A. <strong> Management of bone metastases.</strong> Eur Rev Med Pharmacol Sci. 2010; 14: 407-414.
                    </p>

                    <p>
                        Karadeniz E, Colangeli S, Ghermandi R, Gasbarrini A, Acaroglu E, Boriani S. <strong> Aneurysmal bone 
                        cyst-like areas as a sign of metastatic disease in the spinal column.</strong> Acta Orthopaedica et 
                        Traumatologica Turcica 2013; 47: 366-369.
                    </p>

                    <p>
                        Piccioli A, Spinelli MS., Forsberg JA, Wedin R, Healey JH, Ippolito V, Daolio PA, 
                        Ruggieri P, Maccauro G, Gasbarrini A, Biagini R, Piana R, Fazioli F, Luzzati A, 
                        Di Martino A, Nicolosi F, Camnasio F, Rosa MA, Campanacci DA, Denaro V, Capanna R. 
                        <strong> How do we estimate survival? External validation of a tool for survival estimation 
                        in patients with metastatic bone disease- decision analysis and comparison of three 
                        international patient populations.</strong> BMC Cancer 2015; 15:424. 
                    </p>

                    <p>
                        Gasbarrini A, Campos WK, Campanacci L, Boriani S. <strong> Electrochemotherapy to metastatic 
                        spinal melanoma: A novel treatment of spinal metastasis?</strong> Spine 2015; 40: E1340-E1346.
                    </p>

                    <p>
                        Pipola V, Terzi S, Tedesco G, Bandiera S, Bròdano GB, Ghermandi R, Evangelisti G, 
                        Girolami M, Gasbarrini A. <strong> Metastatic epidural spinal cord compression: does timing of 
                        surgery influence the chance of neurological recovery? An observational case-control 
                        study.</strong> Support Care Cancer. 2018; 26: 3181- 3186. 
                    </p>

                    <p>Ghermandi R, Evangelisti G, Girolami M, Pipola V, Bandiera S, Barbanti-Brodano G, Griffoni G, Tedesco G, Terzi S, Gasbarrini 
                    A. <strong>Management of Metastases to Spine and Sacrum. Management of Bone Metastases.</strong> Book Chapter (2018)</p>

                    <p>Gasbarrini A, Evangelisti G, Ghermandi R, Girolami M, Tedesco G, Pipola V, Boriani S. <strong>Management of Metastatic Spinal Cord
                    Compression without Stereotactic Radiotherapy and Targeted Adjuvant Chemotherapy.</strong> Book Chapter</p>

                    <p>Gasbarrini A. Linee Guida AIOM. <strong>Emergenze ed Urgenze in Oncologia: Compressione midollare</strong> (2019)</p>

                    </div>
                    
                </Col>
            </Row>
        </div>
    )
}

export default Bibiliography

import React from 'react'
import { Row, Col } from 'react-materialize';
import NavBarMenu from '../../../NavBarMenu/NavBarMenu'
import LogoMobile from '../../../../assets/logo/logo_mobile.svg'
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';
import LogoIOR from '../../../../assets/img/ior-logo.png'
import LogoProbone from '../../../../assets/img/probone-logo.jpg'
import LogoWEM from '../../../../assets/img/webandmore-logo.png'


const Credits = () => {
    return (
        <div>
            <Row>
                <Col l={8} m={6} s={12} className="right-section">
                <div>            
                    <NavBarMenu logo={LogoMobile}></NavBarMenu>   
                </div>
                </Col> 
                <Col l={4} m={6} s={12}>
                <div className="mobile-page">
                    <h4 className="blue-title">Credits</h4>
                    <h5>Concept and design</h5>
                    <p>Alessandro Gasbarrini, MD
                    <br></br>
                    Department of Oncological and Degenerative Spine Surgery,
                    <br></br>
                    Rizzoli Institute, Bologna, Italy
                    </p>

                    <a rel="noopener noreferrer" target="_blank" href="http://www.ior.it/curarsi-al-rizzoli/chirurgia-vertebrale-indirizzo-oncologico-e-degenerativo">
                    <img height="70px" src={LogoIOR} alt="ior logo" />
                    </a>
                    <hr></hr>
                    <h5>Creative design &amp; technical development</h5>
                    <p><strong>Web and More srl, Bologna</strong></p>
                    <a rel="noreferrer noopener" target="_blank" href="https://www.webandmore.it">
                    <img height="70px" src={LogoWEM} alt="web and more logo" /></a>
                    <p>
                        Marco Nesi
                        <br></br>
                        Eric Vleugels
                        <br></br>
                        Thomas Brandoli                    
                    </p>
                    <hr></hr>
                    <h5>Fondazione Probone Italia</h5>
                    <a rel="noreferrer noopener" target="_blank" href="https://www.probone.org">
                    <img height="70px" src={LogoProbone} alt="probone logo" /></a>
                    <hr></hr>
                    <p>The also are grateful to the following people for their contribution:</p>
                    <ul>
                        <li>Michele Cappuccio, MD</li>
                        <li>Stefano Boriani, MD</li>
                        <li>Stefano Bandiera, MD</li>
                        <li>Giovanni Barbanti Bròdano, MD</li>
                        <li>Silvia Terzi, MD</li>                        
                        <li>Riccardo Ghermandi, MD</li>
                        <li>Giuseppe Tedesco, MD</li>
                        <li>Gisberto Evangelisti, MD</li>
                        <li>Carlotta Cavallari, MD</li>
                        <li>Marco Girolami, MD</li>
                        <li>Valerio Pipola, MD</li>
                        <li>Carlo Piovani</li>                        
                        <li>Cristiana Griffoni</li>
                        <li>Geraldine Venturoli</li>           
                    </ul>          

                </div>
                </Col>
            </Row>
        </div>
    )
}

export default Credits

import React, { Component } from 'react';
// import styled, { keyframes } from "styled-components";
// import { ReactComponent as ImportedComponent } from '../../assets/logo/logo_mobile.svg'
// import NewLogo from '../../assets/logo/logo_mobile.svg'
import OldLogo from '../../assets/logo/logo_mobile.svg'
import LogoPageMobile from '../LogoPageMobile/LogoPageMobile'
import QuestionFlow from '../QuestionFlow/QuestionFlow'
import NavBarMenu from '../NavBarMenu/NavBarMenu'
import Consent from '../Consent/Consent';
import '../../App.css';
import { Row, Col, MediaBox } from 'react-materialize';
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';
import './MainPageMobile.css'

import decompressione01 from '../../assets/modal-img/DECOMPRESSIONE-01.svg'
import emb_tavoladisegno1 from '../../assets/modal-img/embolizzazione_Tavola_disegno_1.svg'
import escc_tavoladisegno1 from '../../assets/modal-img/ESCC_Tavola_disegno_1.svg'
import sins_tavoladisegno1 from '../../assets/modal-img/SINS_Tavola_disegno_1.1.svg'
import debulking01 from '../../assets/modal-img/DEBULKING-01.svg'
import enbloc01 from '../../assets/modal-img/EN_BLOC-01.svg'
import VTP01 from '../../assets/modal-img/VTP-01.svg'
import mininvasiva from '../../assets/modal-img/MINI-nuova.svg'

// Modal pages

const modalText = {

  "0": (
    <div>
      <p>Determination of operability involves a system evaluation of the general condition of the patient, considering the presence and severity of comorbidities, the oncological prognosis and the willingness of patient to undergo major surgery. At this point, Anestesiologist’s and Oncologist’s opinions are invaluable. Some scores help clinicians in processing the operability judgment:</p>
        <h5>ASA physical status classification</h5>
        <p>
          It was adopted in 1963 by the American Society of Anesthesiologists (ASA).
          The classification alone does not predict the perioperative risks but, 
          used with other factors (ex: type of surgery, fraility, leve of deconditioning), 
          it can be used in predicting perioperative risks.
        </p>
          <ol>
            <li>A normal and healthy patient.</li>
            <li>A patient with mild systemic disease.</li>
            <li>A patient with severe systemic disease.</li>
            <li>A patient with severe systemic disease that is a constant threat to life.</li>
            <li>A moribund patient who is not expected to survive without the operation.</li>
            <li>A declared brain-dead patient whose organs are being removed for donor purposes.</li>
          </ol>
        <a className="a-modal" href="https://www.asahq.org" rel="noopener noreferrer" target="_blank">www.asahq.org</a>

        <h5>Karnofsky Scoring</h5>
        <hr></hr>
        <p>The Karnofsky score runs from 100 to 0,
        where  100 is "perfect" health and 0 is death.
        </p>

        <ul>
          <li>100% normal, no complaints, no signs of disease</li>
          <li>90% capable of normal activity, few symptoms or signs of disease</li>
          <li>80% normai activity with some difficulty, some symptoms or signs</li>
          <li>70% caring for self, not capable of normal activity or work</li>
          <li>60% requiring some help, can take care of most personal requirements</li>
          <li>50% requires help often, requires frequent medical care</li>
          <li>40% disabled, requires special care and help</li>
          <li>30% severely disabled, hospital admission indicated but no risk of death</li>
          <li>20% very ill, urgently requiring admission, requires supportive measures or treatment</li>
          <li>10% moribund, rapidly progressive fatal disease processes</li>
          <li>0% death.</li>
        </ul>
          <p><i>Schag CC, Heinrich RL, Ganz PA. Karnofsky performance status revisited: Reliability, validity, and guidelines. J Clin Oncology.
          1984; 2:187-193.</i></p>



        <h5>ECOG Score</h5>
        <hr></hr>
        <p>The ECOG score, also called the WHO or Zubrod score, runs from 0 to 5, 
        with 0 denoting perfect health and 5 death.</p>
        <ol>
          <li>Asymptomatic (Fully active, able to carry
          on all pre-disease activities without restriction)</li>

          <li>Symptomatic but completely ambulatory (Restricted in physically strenuous activity but
             ambulatory and able to carry out work of a light or sedentary nature. 
             For example, light housework, office work)</li>

          <li>Symptomatic, &lt; 50% in bed during the day (Ambulatory and capable of all self care but
            unable to carry out any work activities. Up and about more than 50% of waking hours)</li>

          <li>Symptomatic, &gt; 50% in bed, but not bedbound (Capable of only limited self-care, 
            confined to bed or chair 50% or more of waking hours)</li>

          <li>Bedbound (Completely disabled.
          Cannot carry on any self-care. Totally confined to bed or chair)</li>

          <li>Death</li>
        </ol>

        <a className="a-modal" href="https://www.ecog-acrin.org" rel="noopener noreferrer" target="_blank">www.ecog-acrin.org</a>

    </div>
  ),
  
  "1.1": (
    <div>
      <p>Neurological compromise secondary to epidural cord compression is probably the worse complication of metastatic involvement of the spine. Evidence has shown great superiority of surgical treatment plus radiotherapy versus radiotherapy alone in restoring neurological functiond and achieving the local tumor control. 
      Proper neurological and imaging evaluation is mandatory, since that earlier detection allows a greater chance of preventing or improving neurological impairment. 
      American Spinal Injury Association Impairment Scale is the most widely accepted tool to evaluate neurological status after a spinal cord injury.</p>
      
      <h5>ASIA impairment scale</h5>
      <ol type="A">
        <li>No motor or sensory function</li>
        <li>Sensory function but no motor function below neurological level</li>
        <li>Partial motor function (most muscles below muscle grade 3)</li>
        <li>Partial motor function (most muscles above muscle grade 3)</li>
        <li>Sensory and motor function is normal</li>
      </ol>

      <a className="a-modal" href="https://www.asia-spinalinjury.org" rel="noopener noreferrer" target="_blank"> www.asia-spinalinjury.org</a>
    </div>
  ),
  
  "1.2": (
    <div>
      <p>Determining the possibility of successful neurological improvement after decompressive surgery is still a matter of debate. However, several factors may orientate the clinician. Incomplete lesions have a greater potential of recovery. Complete paralysis lasting longer 48h or paralysis of sudden onset (suggesting a vascular ischemic process) usually carry out the worse prognosis.</p>
      <br></br>
      <p><i>Quraishi NA, Rajagopal TS, Manoharan SR, Elsayed S, Edwards KL, Boszczyk BM. Effect of timing of surgery on neurological outcome and survival in metastatic spinal cord compression. Eur Spine J. 2013 Jun;22(6):1383-8</i></p>
    </div>
  ),
  
  "2.1": (
    <div>
      <p>Multidisciplinary approach is the mainstay of management of spinal metastastes. Interaction between Oncologist, Radiotherapist and Surgeon is of paramount importance in the choise of the best treatment tailored on each patient. Case evaluation should consider primary tumor histology, presence of specific receptors, history of prior treatments and their effectiveness.
        If a surgical treatment is indicated, the proper timing must be individuated in relation to medical therapies. Medical therapies, in fact, cannot be delayed since that they represent the main tool to achieve systemic and local control of disease.</p>
      
      <p><strong>Istituto Ortopedico Rizzoli:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.ior.it">www.ior.it</a>

      <p><strong>Associazione Italiana di Oncologia Medica:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.aiom.it">www.aiom.it</a>

      <p><strong>European Society for Medical Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.esmo.org">www.esmo.org</a>

      <p><strong>American Society of Clinical Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.asco.org">www.asco.org</a>

      <p><strong>The Cochrane library:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.thecochranelibrary.com">www.thecochranelibrary.com</a>

      <p><strong>NICE guidance by topic:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.nice.org.uk/guidance">www.nice.org.uk/guidance</a>

      <p><strong>Associazione Italiana di Radioterapia Oncologica:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.radioterapiaitalia.it">www.radioterapiaitalia.it</a>

      <p><strong>European Society for Therapeutic Radiology and Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.estro.org">www.estro.org</a>

      <p><strong>American Society for Radiation Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.astro.org">www.astro.org</a>
    </div>
  ),
  
  "2.2": (
    <div>
      <p>Spinal mechanical instability is associated with a weith-bearing pain, risk to develop an impending pathological fracture or a deformity that can further worse neurological function. Imaging criteria can help to improve clinical judgement.</p>
      <p>A scoring system has been developed in the Spine Oncology Study Group in order to help to solve this particular difficult issue.</p>
      {/* <table>
        <thead>
          <th>Location</th>
          <th>Score</th>
        </thead>
        <tbody>

          <tr>
            <td>Junctional (occiput - C2, C7-T2,TI 1-LI, L5-SI)</td>
            <td>3</td>
          </tr>

          <tr>
            <td>Mobile spine (C3-C6, L2-L4)</td>
            <td>2</td>
          </tr>

          <tr>
            <td>Semi-rigid (T3-T IO)</td>
            <td>1</td>
          </tr>

          <tr>
            <td>Rigid (S2-S5)</td>
            <td>0</td>
          </tr>

          <h5>Pain relief with recumbency and/or pain with movement/loading of the spine</h5>

          <tr>
            <td>Yes</td>
            <td>3</td>
          </tr>

          <tr>
            <td>No (occasional pain but not mechanical</td>
            <td>1</td>
          </tr>

          <tr>
            <td>Pain free lesion</td>
            <td>0</td>
          </tr>

          <h5>Bone lesion</h5>

          <tr>
            <td>Lyric</td>
            <td>2</td>
          </tr>

          <tr>
            <td>Mixed (lytic / blastic)</td>
            <td>1</td>
          </tr>

          <tr>
            <td>Blastic</td>
            <td>0</td>
          </tr>

          <h5>Radiographic spinal alignment</h5>

          <tr>
            <td>Subluxation/translation present</td>
            <td>4</td>
          </tr>

          <tr>
            <td>De novo deformity (kyphosis/scoliosis)</td>
            <td>2</td>
          </tr>

          <tr>
            <td>Normal alignment</td>
            <td>0</td>
          </tr>
          
          <h5>Vertebral body collapse</h5>

          <tr>
            <td>&gt; 50% collapse</td>
            <td>3</td>
          </tr>

          <tr>
            <td>&lt; 50% collapse</td>
            <td>2</td>
          </tr>

          <tr>
            <td>No collapse with &gt; 50% body involved</td>
            <td>1</td>
          </tr>

          <tr>
            <td>None of the above</td>
            <td>0</td>
          </tr>

          <h5>Posterolateral involvement of the spinal elements 
          (facet, pedicle or CV joint fracture or replacement with tumor)</h5>

          <tr>
            <td>Bilateral</td>
            <td>3</td>
          </tr>

          <tr>
            <td>Unilateral</td>
            <td>1</td>
          </tr>

          <tr>
            <td>None of the above</td>
            <td>0</td>
          </tr>

        </tbody>
      </table> */}

      <MediaBox><img width="100%" alt="sins_tavola disegno 1" src={sins_tavoladisegno1} /></MediaBox>

      <p><i>Fourney DR et al. Spine instability neoplastic score: an analysis of reliability and validity from the spine oncology study group. J Clin Oncol. 2011 Aug 1;29(22):3072-7</i></p>
    </div>
  ),
  
  "2.3": (
    <div>
      <p>In case of spinal instability, stabilization (even with minimally invasive techniques) must be undertaken. Decompression and Debulking should be addressed when a symptomatic cord compression occurs or in case of asymptomatic epidural compression from a radio-resistant metastasis. Separation surgery, that is the separation of the tumor from nervous structures, allows to perform Stereotactic radiosurgery (SRS) and Hadrontherapy. 
      When SRS or Hadrontherapy are planned, stabilization may be achieved with carbon fiber reinforced peek instrumentation (even with minally invasive techniques).
      The choise to perfom separation surgery should be guided by the Epidural Spinal Cord Compression Scale that takes in the account the cord compression degree and the sensitivity of the primary tumor to radiotherapy.</p>
      <img alt="escc_tavola_disegno_1" width="100%" src={mininvasiva} />
      <img alt="escc_tavola_disegno_1" width="100%" src={escc_tavoladisegno1} />

      <p><i>Bilsky MH, Laufer I, Fourney DR, Groff M, Schmidt MH, Varga PP, Vrionis FD, Yamada Y, Gerszten PC, Kuklo TR. J Neurosurg Spine. 2010 Sep;13(3):324-8</i></p>
    </div>
  ),

  "2.4": (
    <div>
      <p>If a symtomatic spinal cord compression occurs, the mainstay of treatment in order to restore neurological functions and spinal stability is decompression and stabilization. This procedure is usually associated to the debulking of neoplastic tissue causing cord compression.</p>
      <p>Preoperative Selective Arterial Embolization (SAE) plays an important role reducing intraoperative bleeding, which in same cases can be life-threatening, above all in hypervascularized metastases, such as renal cell carcinoma and thyroid mets.</p>
      <MediaBox><img width="100%" alt="tavola disegno 1" src={emb_tavoladisegno1} /></MediaBox>
      <p><i>Clausen C, Dahl B, Frevert SC, Hansen LV, Nielsen MB, Lönn L. Preoperative embolization in surgical treatment of spinal metastases: single-blind, randomized controlled clinical trial of efficacy in decreasing intraoperative blood loss. J Vasc Inerv Radiol.  2015 Mar;26(3):402-12 </i></p>
      <p>Stabilization is mandatory since that decompression increases spine instability that is associated with the worsening of neurological function.</p>
      <MediaBox><img width="100%" alt="decompressione" src={decompressione01} /></MediaBox>
      <p><i>Patchell RA, Tibbs PA, Regine WF, Payne R, Saris S, Kryscio RJ, Mohiuddin M, Young B. Direct decompressive surgical resection in the treatment of spinal cord compression caused by metastatic cancer: a randomized trial. Lancet. 2005 Aug 20-26;366(9486):643-8</i></p>
    </div>
  ),
  
  "3.1": (
    <div>
      <p>Primary tumor staging is of paramount importance in order to rule out the presence of other disease localization. If the primary tumor is not sensitive to medical therapies and there is a solitary metastasis, aggressive local excision is still recommended, since it is the only available alternative to alter the natural history of disease and to achieve the local tumor control.</p>
    </div>
  ),
  
  "3.2": (
    <div>
      <h5>Debulking (intralesional piecemeal excision)</h5>
      <p>Debulking involves removing as such macroscopic disease as possible in an intralesional piecemeal fashion. It can be achieved by a single approach or through the combination of approaches and, usually, requires more demanding reconstruction for achieving stability. Since this procedure involves working inside the tumor, major bleeding coulf be expected, so preoperative Selective Arterial Embolization (SAE) is highly recommanded.</p>
      <MediaBox><img width="100%" alt="debulking" src={debulking01} /></MediaBox>

      <h5>En Bloc Resection</h5>
      <p>En bloc resection means the removal of tumor with a layer of healthy tissue. In some circumstance it can correspond to the removal of the entire vertebra. It may be achieved by a single approach or through the combination of approaches depending on local tumor extension. This procedure is technically demanding and it associated with high morbidity and should be reserved only in selected patients.</p>
      <MediaBox><img width="100%" alt="en bloc" src={enbloc01} /></MediaBox>
    </div>
  ),
  
  "4.1": (
    <div>
      <p>In patients with multiple secondary localizations, both visceral or skeletal, local treatment is conditioned by their prognosis. Whenever local control of the other metastases is possible, either by surgical or medical means, surgical management of spinal lesions is favored. On the other hand, patients with multiple localizations, not amenable to effective disease control measures and with a poor general prognosis, are not candidates for surgical treatment. In these patients, decision making process requires the opinion of the others medical figures involved in the multidisciplinary approach to cancer patient.</p>
    </div>
  ),
  
  "5.1": (
    <div>
      <p>Patients that are judged to be beyond the reach of surgical and non-operative oncological therapies should be placed under palliative care in order to receive appropriate pain alleviation. This is usually best accomplished under the guidance of specialized units and following euthe local or international guidalines.</p>

      <p><strong>Associazione Italiana Studio del Dolore:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.aisd.it/">www.aisd.it</a>

      <p><strong>Società Italiana di Anestesia Analgesia Rianimazione e Terapia Intensiva:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.siaarti.it/">www.siaarti.it</a>

      <p><strong>Società Italiana di Anestesia Rianimazione e Dolore:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.siared.it/">www.siared.it</a>

      <p><strong>European Society of Regional Anesthesia and Pain Therapy:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.esraeurope.org/">www.esraeurope.org</a>

      <p><strong>American Society of Regional Anesthesia and Pain Medicine:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.asra.com/">www.asra.com</a>
    
      <p>Vertebroplasty is minimally invasive procedure usually performed and local anesthesia that should be considered in patients with considerable mechanical pain due to mild instability and who are not good surgical candidates.</p>

      <MediaBox><img width="100%" alt="VTP" src={VTP01} /></MediaBox>

      <p>If no pain control is achieved with this regime and the patient becomes operable at certain point, surgical management should be considered.</p>
    
    </div>
  ),
  
  "6.1": (
    <div>
      <p>Multidisciplinary approach is the mainstay of management of spinal metastastes. Interaction between Oncologist, Radiotherapist and Surgeon is of paramount importance in the choise of the best treatment tailored on each patient. Case evaluation should consider primary tumor histology, presence of specific receptors, history of prior treatments and their effectiveness.
        If a surgical treatment is indicated, the proper timing must be individuated in relation to medical therapies. Medical therapies, in fact, cannot be delayed since that they represent the main tool to achieve systemic and local control of disease.</p>
      
      <p><strong>Istituto Ortopedico Rizzoli:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.ior.it">www.ior.it</a>

      <p><strong>Associazione Italiana di Oncologia Medica:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.aiom.it">www.aiom.it</a>

      <p><strong>European Society for Medical Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.esmo.org">www.esmo.org</a>

      <p><strong>American Society of Clinical Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.asco.org">www.asco.org</a>

      <p><strong>The Cochrane library:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.thecochranelibrary.com">www.thecochranelibrary.com</a>

      <p><strong>NICE guidance by topic:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.nice.org.uk/guidance">www.nice.org.uk/guidance</a>

      <p><strong>Associazione Italiana di Radioterapia Oncologica:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.radioterapiaitalia.it">www.radioterapiaitalia.it</a>

      <p><strong>European Society for Therapeutic Radiology and Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.estro.org">www.estro.org</a>

      <p><strong>American Society for Radiation Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.astro.org">www.astro.org</a>
    </div>
  ),
  
  "7.1": (
    <div>
      <p>Combination of chemo, radio, hormone and immuno-therapies should be tailored to the specific patient and histology involved and represents the first-line treatment of spinal metastases. Surgery, in fact, is usually not curative and has an ancillary role. Its aims are: to preserve/recover neurological function; pain relief; to guarantee spine stability and to allow medical therapy to achieve local tumor control.</p>
    </div>
  )

}

const decodeEntitiesHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const symbol = decodeEntitiesHtml('&plusmn;')

export const questions = {
  "0": { 
    question: "Patient operable?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '1.1'},
      {value: 'No', answer: 'No', next: '6.1'}
    ],
    info: modalText["0"],
    pdf: "1"
  },

  "1.1": {
    question: "Symptomatic spinal cord compression?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '1.2'},
      {value: 'No', answer: 'No', next: '2.1'}
    ],
    info: modalText["1.1"],
    pdf: "2"
  },

  "1.2": {
    question: "Chance of neurological recovery?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '2.4'},
      {value: 'No', answer: 'No', next: '2.1'}
    ],
    info: modalText["1.2"],
    pdf: "3"
  },

  // //#region DELETED STEPS
  // DEPRECATED
  // "1.3": {
  //   question: "Increasing neurological damage?",
  //   answers: [
  //     {value: 'Yes', answer: 'Yes', next: '1.4'},
  //     {value: 'No', answer: 'No', next: '2.1'}
  //   ],
  //   info: modalText["1.3"],
  //   pdf: "4"
  // },

  // DEPRECATED
  // "1.4": {
  //   question: "Emergency surgery",
  //   answers: [
  //     {value: 'Yes', answer: 'Yes'},
  //     {value: 'No', answer: 'No'}
  //   ],
  //   info: modalText["1.4"],
  //   pdf: "5"
  // },
  // //#endregion

  "2.1": {
    question: "Sensitivity to non-surgical therapy?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '2.2'},
      {value: 'No', answer: 'No', next: '3.1'}
    ],
    info: modalText["2.1"],
    pdf: "6"
  },

  "2.2": {
    question: "Risk of pathological fracture/instability?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '2.3'},
      {value: 'No', answer: 'No', next: '7.1'}
    ],
    info: modalText["2.2"],
    pdf: "7"
  },

  "2.3": {
    question: `Stabilization ${symbol} decompression`,
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["2.3"],
    pdf: "8"
  },

  "2.4": {
    question: `Decompression and stabilization`,
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["2.4"],
    pdf: "8"
  },

  "3.1": {
    question: "Isolated metastasis?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '3.2'},
      {value: 'No', answer: 'No', next: '4.1'}
    ],
    info: modalText["3.1"],
    pdf: "9"
  },

  "3.2": {
    question: "Excision",
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["3.2"],
    pdf: "l"
  },

  "4.1": {
    question: "Therapeutic possibilities in other localizations?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '3.2'},
      {value: 'No', answer: 'No', next: '5.1'}
    ],
    info: modalText["4.1"],
    pdf: "m"
  },
  
    "5.1": {
    question: `Pain therapy ${symbol} vertebroplasty`,
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["5.1"],
    pdf: "n"
  },

  "6.1": {
    question: `Sensitivity to non-surgical therapy?`,
    answers: [ 
      {value: 'Yes', answer: 'Yes', next: '7.1'},
      {value: 'No', answer: 'No', next: '5.1'}
    ],
    info: modalText["6.1"],
    pdf: "o"
  },

  "7.1": {
    question: `Non-Surgical therapy`,
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["7.1"],
    pdf: "p"
  }
}

export default class MainPageMobile extends Component {

  state = {
    isChecked: false,
    haveConsent: false,
    buttonConsent: false,
    logoPage: true
  }

  componentDidMount() {
    setInterval(() => {
      this.setState({logoPage: false});
      (function(w){w = w || window; var i = w.setInterval(function(){},100000); while(i>=0) { w.clearInterval(i--); }})(/*window*/);
    }, 2500);

  }

  handleCheck = () => {
    const button_c = document.getElementById("button-consent");
    if(this.state.isChecked === false) {
        button_c.disabled = false;
        this.setState({isChecked: true})
    }

    else if(this.state.isChecked === true) {
        button_c.disabled = true;
        this.setState({isChecked: false})
    }
  }

  handleButton = () => {
    this.setState({buttonConsent: true})

    if(!this.state.buttonConsent)
    {
      this.setState({haveConsent: true})
    }
  }

  render() {

    // #region logo animation

      //  const pulse = keyframes`
      //  0% {
      //  transform: scale(1.2);
      //  transform-origin: center;
      //  }
  
      //  100% {
      //  transform: scale(1);
      //  transform-origin: center;
      //  }
      //  `;
  
      // const AnimatedSVG = styled(ImportedComponent)`
      //  display: block;
      //  margin: auto;
      //  width: 9vh;
      //  .cuore {
      //      animation: ${pulse} infinite alternate 1s linear;
      //  };
      //  .cuore_2 {
      //      animation: ${pulse} infinite alternate 1s linear;
      //  };
      //  `;

  // #endregion

    let chatSection;
    const { haveConsent } = this.state;
    const { isChecked } = this.state;

    if(haveConsent && isChecked)
    {
      chatSection = (<QuestionFlow questions={questions} initialQuestion="0"></QuestionFlow> )
    } else {
      chatSection = (<Consent value={this.state.buttonConsent} buttonClick={this.handleButton} event={this.handleCheck} />)
    }

    const initialPage = this.state.logoPage
    let page;

    if(initialPage) {
      page = (<LogoPageMobile></LogoPageMobile>)
    } else {
      page = (<Row>
        <Col l={8} m={6} s={12}>
            <div>            
                <NavBarMenu logo={OldLogo}></NavBarMenu>
            </div>
        </Col> 
        <Col l={4} m={6} s={12}>
          {chatSection}
        </Col>
      </Row>)
    }

    return (
      <div>
        {page}
    </div>
    );
  }
}

import React from 'react'
import { Row, Col } from 'react-materialize';
import NavBarMenu from '../../../NavBarMenu/NavBarMenu'
import LogoMobile from '../../../../assets/logo/logo_mobile.svg'
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';

const HowToQA = () => {
    return (
        <div>
            <Row>
                <Col l={8} m={6} s={12} className="right-section">
                <div>            
                    <NavBarMenu logo={LogoMobile}></NavBarMenu>   
                </div>
                </Col> 
                <Col l={4} m={6} s={12}>
                <div className="mobile-page">
                <h4 className="blue-title">How To Q &amp; A</h4>
                <p><strong><i>A. How to add the "metastases-algorithm.com" icon to your mobile phone's home screen? *</i></strong></p>
                <p><strong>Android</strong></p>
                    <ol>
                        <li>Launch your browser (e.g. Chrome) and navigate to "www.metastases-algorithm.com".</li>
                        <li>Tap on the icon "hamburger menu", i.e. the three dots icon which is usually in the right corner at the top of your screen.</li>
                        <li>It will pop-up a menu. Select and tap the "Add to Home screen" menu item.</li>
                        <li>It will open a window. Confirm (by clicking add) the suggested name for the shortcut "Spinal Metastases Algorithm", or enter one you like better.</li>
                        <li>This will open another window. You can tap "Add automatically", but it might be more convenient to place it manually, 
                        by touching and holding the icon. It permits to drag the icon the the desired position among your aother icons on one of your home-screens.</li>
                        <li>You're now ready to visit the webapp by clicking next time on the new shortcut. *</li>
                    </ol>

                <p><strong>iOS and iPadOS</strong></p>
                    <ol>
                        <li>Launch your Safari browser and navigate to "www.metastases-algorithm.com".</li>
                        <li>Tap the Bookmark icon at the bottom of the screen (the upward-pointing arrow).</li>
                        <li>Tap "Add to Home Screen".</li>
                        <li>Confirm the suggested name for the shortcut "Spinal Metastases Algorithm", or enter one you like better.</li>
                        <li>Tap "Add to save the new shortcut icon to the home screen".</li>
                        <li>You're now ready to visit the webapp by clicking next time on the new shortcut. *</li>
                    </ol>
                <p>
                Read also:<br></br>
                <a rel="noopener noreferrer" target="_blank" href="https://www.lifewire.com/home-screen-icons-in-safari-for-iphone-and-amp-ipod-touch-4103654">Make a Safari Shortcut on Your iPhone, iPad, or iPod touch Home Screen</a>
                </p>
                <p><i>* Once you've created a shortcut to the url of the algorithm, it can be used even without internet connection, but flow-files will 
                be downloaded once you are online again.</i></p>
                <p><strong><i>B. Downloading files on the latest iOS, or iPadOS</i></strong></p>
                <p>It is useful to know how to configure download folders on your Iphone or Ipad and where files are downloaded to by default. 
                Maybe it's useful to read the support page in the apple website:</p>
                <a rel="noopener noreferrer" target="_blank" href="https://support.apple.com/en-us/HT206481"> Use the Files app on your iPhone, iPad, or iPod touch</a>
                
                <p>or this article:</p>
                <a rel="noopener noreferrer" target="_blank" href="https://www.lifewire.com/how-to-find-downloads-on-phone-4587236">Where Are The Downloads On My Phone?</a>

                </div>
                </Col>
            </Row>
        </div>
    )
}

export default HowToQA

import React from 'react'
import './NavBarMenu.css'
import { Navbar, NavItem } from 'react-materialize'
import  { Breakpoint } from 'react-socks';
import { Link } from 'react-router-dom'
// import LogoMobile from '../../assets/logo/logo-mobile.png'

const NavBarMenu = (props) => {

    return (
        <div className="navbar-menu">        
        <Breakpoint m up>
            <Navbar alignLinks={props.position}>
                <NavItem data-page="thealgorithm" onClick={props.selectPage}>The Algorithm</NavItem>
                <NavItem data-page="about" onClick={props.selectPage}>About Us</NavItem>
                <NavItem data-page="bibliography" onClick={props.selectPage}>Bibliography</NavItem>
                <NavItem data-page="disclaimer" onClick={props.selectPage}>Disclaimer</NavItem>
                <NavItem data-page="how-to-qa" onClick={props.selectPage}>How to Q&A</NavItem>
                <NavItem data-page="credits" onClick={props.selectPage}>Credits</NavItem>
                <NavItem target="_blank" href="https://www.iubenda.com/privacy-policy/14563988">Privacy</NavItem>
            </Navbar>
        </Breakpoint>
        <Breakpoint small down>
            <Navbar className="navbar-mobile" alignLinks="right" brand={<Link to="/"><img src={props.logo} alt="logo" height="46px"></img></Link>}>
                <NavItem><img src={props.logo} alt="logo" height="46px" /></NavItem>
                <NavItem><Link to="/">Home</Link></NavItem>
                <NavItem><Link to="/the-algorithm/">Algorithm &amp; Flow Chart</Link></NavItem>
                <NavItem><Link to="/about/">About</Link></NavItem>
                <NavItem><Link to="/bibliography/">Bibliography</Link></NavItem>
                <NavItem><Link to="/disclaimer/">Disclaimer</Link></NavItem>
                <NavItem><Link to="/how-to/">How to Q&A</Link></NavItem>
                <NavItem><Link to="/credits/">Credits</Link></NavItem>
                <NavItem target="_blank" href="https://www.iubenda.com/privacy-policy/14563988">Privacy</NavItem>
            </Navbar>
        </Breakpoint>

        </div>
    )
}

export default NavBarMenu
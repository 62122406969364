import React from 'react'
// import Logo from '../../assets/logo/logo_splash.svg'
import '../../App.css'
import styled, { keyframes } from "styled-components";
import { ReactComponent as ImportedComponent }  from '../../assets/logo/logo_splash.svg'

const fadeIn = keyframes`
0% {
opacity: 0
}    
100% {
opacity: 1
}
`;

const cuoreAnim = keyframes`
0% {
    opacity: 0
    transform: scale(1.5)
}

30% {
    opacity: 0.5
}

50%{
    opacity: 1
}

100% {
    transform: scale(1)
}

`;
    const AnimatedSVG = styled(ImportedComponent)`
    width: 200px;
    .fullSvg {
        animation: ${fadeIn} 1 2s linear;
    }
    .cuore_splash {
        animation: ${cuoreAnim} 1 2s ease-in;
        opacity: 1!important;
    };
`;


const LogoPageMobile = () => {
    return (
        <div class="logopagemobile">
            <AnimatedSVG />
        </div>
    )
}

export default LogoPageMobile

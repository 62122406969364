import React from 'react'
import { Row, Col, MediaBox, Button, Icon } from 'react-materialize';
import NavBarMenu from '../../../NavBarMenu/NavBarMenu'
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';
import flowchartImg from '../../../../assets/img/image-complete-flowchart.svg'
import flowchartPdf from '../../../../assets/download/complete-flowchart.pdf'
import LogoMobile from '../logo_mobile.svg'

const TheAlgorithm = () => {
    return (
        <div>
        <Row>
            <Col l={8} m={6} s={12} className="right-section">
            <div>            
                <NavBarMenu logo={LogoMobile}></NavBarMenu>
            </div>
            </Col> 
            <Col l={4} m={6} s={12}>
            <div className="mobile-page">
                <h4 className="blue-title">Algorithm &amp; Flow Chart</h4>
                <p>The aim of surgical treatment of vertebral metastases are: to preserve or, if possible, recover 
                neurological function; pain relief; guarantee the stability of the spine and local tumor control.
                This algorithm has been designed in order to achieve these objectives, based on the patient’s operability, 
                on the sensitivity of the primary neoplasm to chemotherapy and radiation therapy and on the patient’s 
                neurological status. <br></br>
                It comes from the experience of <strong>Department of Oncological and Degenerative Spine Surgery of Rizzoli Orthopedic 
                Institute</strong> and it was validated by a firstly retrospective and a subsequent multicentric prospective study. </p>
                <p>This <strong>flow-chart</strong> is not only addressed for spine surgeons but for all actors (oncologists, radiotherapists, 
                anesthesiologists) involved in the multidisciplinary approach to a patient with cancer.</p>
                
                <MediaBox>
                <img src={flowchartImg} width="100%" alt="" />
                </MediaBox>
                <br></br>
                <a href={flowchartPdf} download="complete-flowchart" rel="noopener noreferrer" target="_blank">
                <Button className="complete-flowchart" waves="light" style={{marginRight: '5px'}} small  download="complete-flowchart">
                <Icon left>
                picture_as_pdf
                </Icon>
                Complete Flowchart
                </Button>
                </a>
                <br></br>
                <br></br>
                <hr></hr>
            </div>
        </Col>
        </Row>
        </div>
    )
}

export default TheAlgorithm

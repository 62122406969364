import React from 'react'
// import { Row, Col } from 'react-materialize';
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartBroken } from '@fortawesome/free-solid-svg-icons'
import '../../App.css'

const PageNotFound = () => {

    const styles = {
        body : {
            position: 'absolute',
            backgroundColor: '#0392AE',
            color: '#fff',
            minWidth: '100%',
            minHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
        },
        link: {
            color: '#fff',
            textDecoration: 'underline',
            fontWeight: '500'
        },
        icon: {
            margin: '0 auto'
        }
    }

    return (
        <div style={styles.body}>
            <FontAwesomeIcon style={styles.icon} size="6x" icon={faHeartBroken} />
            <div>
                <h1>Oops!</h1>
                <h1>We can't find that page.</h1>
            </div>
            <p>Take me back to <a style={styles.link} rel="noopener noreferrer" target="_self" href="https://metastases-algorithm.com">www.metastases-algorithm.com</a></p>
        </div>
    )
}

export default PageNotFound

import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import MainPage from './components/MainPage/MainPage'
import MainPageMobile from './components/MainPageMobile/MainPageMobile'
import TheAlgorithm from './components/Pages/MobilePages/TheAlgorithm/TheAlgorithm'
import About from './components/Pages/MobilePages/About/About'
import Bibliography from './components/Pages/MobilePages/Bibliography/Bibiliography'
import Disclaimer from './components/Pages/MobilePages/Disclaimer/Disclaimer'
import Credits from './components/Pages/MobilePages/Credits/Credits'
import HowToQA from './components/Pages/MobilePages/HowToQA/HowToQA'
import PageNotFound from './components/PageNotFound/PageNotFound'
import  { Breakpoint, BreakpointProvider, setDefaultBreakpoints } from 'react-socks';
import ReactGA from 'react-ga'

// fix window height
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

ReactGA.initialize('GA UA-150906959-1');
ReactGA.pageview(window.location.pathname + window.location.search);

export default class App extends Component {
  
  render() {
    setDefaultBreakpoints([
      { xsmall: 0 },
      { small: 600 },
      { m: 601 },
      { tablet: 992}
    ]);

    return (
      <div>
      <BreakpointProvider>
      <Breakpoint m up>
      <Router basename={'/'}>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
      </Breakpoint>

      <Breakpoint small down>
      <Router basename={'/'}>
        <Switch>
          <Route exact path="/" component={MainPageMobile} />
          <Route exact path="/the-algorithm/" component={TheAlgorithm} />
          <Route exact path="/bibliography/" component={Bibliography} />
          <Route exact path="/disclaimer/" component={Disclaimer} />
          <Route exact path="/how-to/" component={HowToQA} />
          <Route exact path="/credits/" component={Credits} />
          <Route exact path="/about/" component={About} />
          <Route component={PageNotFound} />
        </Switch>
      </Router>
      </Breakpoint>
      </BreakpointProvider>
    </div>
    );
  }
}
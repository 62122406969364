import React from 'react'
import '../../../../App.css'
import { Row, Col } from 'react-materialize';
import NavBarMenu from '../../../NavBarMenu/NavBarMenu'
import LogoMobile from '../../../../assets/logo/logo_mobile.svg'
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';

const Disclaimer = () => {
    return (
        <div>
            <Row>
            <Col l={8} m={6} s={12}>
                <div>            
                <NavBarMenu logo={LogoMobile}></NavBarMenu>
                </div>
            </Col> 
            <Col l={4} m={6} s={12}>
            <div className="mobile-page">
                <h4 className="blue-title">Disclaimer</h4>
                <p>All the information on this website - https://metastases-algorithm.com/webapp - is published in good faith and for general information purpose only. Metastases algorithm does not make any warranties about the completeness, reliability and accuracy of these informations. Any action you take upon the information you find on this website, is strictly at your own risk. metastases algorithm will not be liable for any losses and/or damages in connection with the use of our website. </p>
                <p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>
                <h5>Consent</h5>
                <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>
                <h5>Update</h5>
                <p>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p>
            </div>
            </Col>
        </Row>
        </div>
    )
}

export default Disclaimer

import React from 'react'
import './InfoWindow.css'

const InfoWindow = (props) => {
    return (
        <div className="info-window">
            {props.content}
        </div>
    )
}

export default InfoWindow

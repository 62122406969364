import React, { Component } from 'react';
import styled, { keyframes } from "styled-components";
import { ReactComponent as ImportedComponent }  from '../../assets/logo/logo_desktop.svg'
import QuestionFlow from '../QuestionFlow/QuestionFlow'
import InfoWindow from '../InfoWindow/InfoWindow'
import NavBarMenu from '../NavBarMenu/NavBarMenu'
import Consent from '../Consent/Consent';
import Footer from '../Footer/Footer'
import '../../App.css';
import { Row, Col, Button, Icon, MediaBox } from 'react-materialize';
import 'materialize-css/dist/css/materialize.min.css';
import 'materialize-css';
import LogoIOR from '../../assets/img/ior-logo.png'
import LogoProbone from '../../assets/img/probone-logo.jpg'
import LogoWEM from '../../assets/img/webandmore-logo.png'
// import LogoDesktop from '../../assets/logo/logo_desktop.svg'
import flowchartImg from '../../assets/img/image-complete-flowchart.svg'
import flowchartPdf from '../../assets/download/complete-flowchart.pdf'
import { isEdge } from 'react-device-detect'
import teamImg from '../../assets/img/team-about-us.jpg'

import decompressione01 from '../../assets/modal-img/DECOMPRESSIONE-01.svg'
import emb_tavoladisegno1 from '../../assets/modal-img/embolizzazione_Tavola_disegno_1.svg'
import escc_tavoladisegno1 from '../../assets/modal-img/ESCC_Tavola_disegno_1.svg'
import sins_tavoladisegno1 from '../../assets/modal-img/SINS_Tavola_disegno_1.1.svg'
import debulking01 from '../../assets/modal-img/DEBULKING-01.svg'
import enbloc01 from '../../assets/modal-img/EN_BLOC-01.svg'
import VTP01 from '../../assets/modal-img/VTP-01.svg'
import mininvasiva from '../../assets/modal-img/MINI-nuova.svg'

// Modal pages

const modalText = {

  "0": (
    <div>
      <p>Determination of operability involves a system evaluation of the general condition of the patient, considering the presence and severity of comorbidities, the oncological prognosis and the willingness of patient to undergo major surgery. At this point, Anestesiologist’s and Oncologist’s opinions are invaluable. Some scores help clinicians in processing the operability judgment:</p>
        <h5>ASA physical status classification</h5>
        <p>
          It was adopted in 1963 by the American Society of Anesthesiologists (ASA).
          The classification alone does not predict the perioperative risks but, 
          used with other factors (ex: type of surgery, fraility, leve of deconditioning), 
          it can be used in predicting perioperative risks.
        </p>
          <ol>
            <li>A normal and healthy patient.</li>
            <li>A patient with mild systemic disease.</li>
            <li>A patient with severe systemic disease.</li>
            <li>A patient with severe systemic disease that is a constant threat to life.</li>
            <li>A moribund patient who is not expected to survive without the operation.</li>
            <li>A declared brain-dead patient whose organs are being removed for donor purposes.</li>
          </ol>
        <a className="a-modal" href="https://www.asahq.org" rel="noopener noreferrer" target="_blank">www.asahq.org</a>

        <h5>Karnofsky Scoring</h5>
        <hr></hr>
        <p>The Karnofsky score runs from 100 to 0,
        where  100 is "perfect" health and 0 is death.
        </p>

        <ul>
          <li>100% normal, no complaints, no signs of disease</li>
          <li>90% capable of normal activity, few symptoms or signs of disease</li>
          <li>80% normai activity with some difficulty, some symptoms or signs</li>
          <li>70% caring for self, not capable of normal activity or work</li>
          <li>60% requiring some help, can take care of most personal requirements</li>
          <li>50% requires help often, requires frequent medical care</li>
          <li>40% disabled, requires special care and help</li>
          <li>30% severely disabled, hospital admission indicated but no risk of death</li>
          <li>20% very ill, urgently requiring admission, requires supportive measures or treatment</li>
          <li>10% moribund, rapidly progressive fatal disease processes</li>
          <li>0% death.</li>
        </ul>
          <p><i>Schag CC, Heinrich RL, Ganz PA. Karnofsky performance status revisited: Reliability, validity, and guidelines. J Clin Oncology.
          1984; 2:187-193.</i></p>

        <h5>ECOG Score</h5>
        <hr></hr>
        <p>The ECOG score, also called the WHO or Zubrod score, runs from 0 to 5, 
        with 0 denoting perfect health and 5 death.</p>
        <ol>
          <li>Asymptomatic (Fully active, able to carry
          on all pre-disease activities without restriction)</li>

          <li>Symptomatic but completely ambulatory (Restricted in physically strenuous activity but
             ambulatory and able to carry out work of a light or sedentary nature. 
             For example, light housework, office work)</li>

          <li>Symptomatic, &lt; 50% in bed during the day (Ambulatory and capable of all self care but
            unable to carry out any work activities. Up and about more than 50% of waking hours)</li>

          <li>Symptomatic, &gt; 50% in bed, but not bedbound (Capable of only limited self-care, 
            confined to bed or chair 50% or more of waking hours)</li>

          <li>Bedbound (Completely disabled.
          Cannot carry on any self-care. Totally confined to bed or chair)</li>

          <li>Death</li>
        </ol>

        <a className="a-modal" href="https://www.ecog-acrin.org" rel="noopener noreferrer" target="_blank">www.ecog-acrin.org</a>

    </div>
  ),
  
  "1.1": (
    <div>
      <p>Neurological compromise secondary to epidural cord compression is probably the worse complication of metastatic involvement of the spine. Evidence has shown great superiority of surgical treatment plus radiotherapy versus radiotherapy alone in restoring neurological functiond and achieving the local tumor control. 
      Proper neurological and imaging evaluation is mandatory, since that earlier detection allows a greater chance of preventing or improving neurological impairment. 
      American Spinal Injury Association Impairment Scale is the most widely accepted tool to evaluate neurological status after a spinal cord injury.</p>
      
      <h5>ASIA impairment scale</h5>
      <ol type="A">
        <li>No motor or sensory function</li>
        <li>Sensory function but no motor function below neurological level</li>
        <li>Partial motor function (most muscles below muscle grade 3)</li>
        <li>Partial motor function (most muscles above muscle grade 3)</li>
        <li>Sensory and motor function is normal</li>
      </ol>

      <a className="a-modal" href="https://www.asia-spinalinjury.org" rel="noopener noreferrer" target="_blank"> www.asia-spinalinjury.org</a>
    </div>
  ),
  
  "1.2": (
    <div>
      <p>Determining the possibility of successful neurological improvement after decompressive surgery is still a matter of debate. However, several factors may orientate the clinician. Incomplete lesions have a greater potential of recovery. Complete paralysis lasting longer 48h or paralysis of sudden onset (suggesting a vascular ischemic process) usually carry out the worse prognosis.</p>
      <br></br>
      <p><i>Quraishi NA, Rajagopal TS, Manoharan SR, Elsayed S, Edwards KL, Boszczyk BM. Effect of timing of surgery on neurological outcome and survival in metastatic spinal cord compression. Eur Spine J. 2013 Jun;22(6):1383-8</i></p>
    </div>
  ),
  
  "2.1": (
    <div>
      <p>Multidisciplinary approach is the mainstay of management of spinal metastastes. Interaction between Oncologist, Radiotherapist and Surgeon is of paramount importance in the choise of the best treatment tailored on each patient. Case evaluation should consider primary tumor histology, presence of specific receptors, history of prior treatments and their effectiveness.
        If a surgical treatment is indicated, the proper timing must be individuated in relation to medical therapies. Medical therapies, in fact, cannot be delayed since that they represent the main tool to achieve systemic and local control of disease.</p>
      
      <p><strong>Istituto Ortopedico Rizzoli:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.ior.it">www.ior.it</a>

      <p><strong>Associazione Italiana di Oncologia Medica:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.aiom.it">www.aiom.it</a>

      <p><strong>European Society for Medical Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.esmo.org">www.esmo.org</a>

      <p><strong>American Society of Clinical Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.asco.org">www.asco.org</a>

      <p><strong>The Cochrane library:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.thecochranelibrary.com">www.thecochranelibrary.com</a>

      <p><strong>NICE guidance by topic:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.nice.org.uk/guidance">www.nice.org.uk/guidance</a>

      <p><strong>Associazione Italiana di Radioterapia Oncologica:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.radioterapiaitalia.it">www.radioterapiaitalia.it</a>

      <p><strong>European Society for Therapeutic Radiology and Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.estro.org">www.estro.org</a>

      <p><strong>American Society for Radiation Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.astro.org">www.astro.org</a>
    </div>
  ),
  
  "2.2": (
    <div>
      <p>Spinal mechanical instability is associated with a weith-bearing pain, risk to develop an impending pathological fracture or a deformity that can further worse neurological function. Imaging criteria can help to improve clinical judgement.</p>
      <p>A scoring system has been developed in the Spine Oncology Study Group in order to help to solve this particular difficult issue.</p>
      {/* <table>
        <thead>
          <th>Location</th>
          <th>Score</th>
        </thead>
        <tbody>

          <tr>
            <td>Junctional (occiput - C2, C7-T2,TI 1-LI, L5-SI)</td>
            <td>3</td>
          </tr>

          <tr>
            <td>Mobile spine (C3-C6, L2-L4)</td>
            <td>2</td>
          </tr>

          <tr>
            <td>Semi-rigid (T3-T IO)</td>
            <td>1</td>
          </tr>

          <tr>
            <td>Rigid (S2-S5)</td>
            <td>0</td>
          </tr>

          <h5>Pain relief with recumbency and/or pain with movement/loading of the spine</h5>

          <tr>
            <td>Yes</td>
            <td>3</td>
          </tr>

          <tr>
            <td>No (occasional pain but not mechanical</td>
            <td>1</td>
          </tr>

          <tr>
            <td>Pain free lesion</td>
            <td>0</td>
          </tr>

          <h5>Bone lesion</h5>

          <tr>
            <td>Lyric</td>
            <td>2</td>
          </tr>

          <tr>
            <td>Mixed (lytic / blastic)</td>
            <td>1</td>
          </tr>

          <tr>
            <td>Blastic</td>
            <td>0</td>
          </tr>

          <h5>Radiographic spinal alignment</h5>

          <tr>
            <td>Subluxation/translation present</td>
            <td>4</td>
          </tr>

          <tr>
            <td>De novo deformity (kyphosis/scoliosis)</td>
            <td>2</td>
          </tr>

          <tr>
            <td>Normal alignment</td>
            <td>0</td>
          </tr>
          
          <h5>Vertebral body collapse</h5>

          <tr>
            <td>&gt; 50% collapse</td>
            <td>3</td>
          </tr>

          <tr>
            <td>&lt; 50% collapse</td>
            <td>2</td>
          </tr>

          <tr>
            <td>No collapse with &gt; 50% body involved</td>
            <td>1</td>
          </tr>

          <tr>
            <td>None of the above</td>
            <td>0</td>
          </tr>

          <h5>Posterolateral involvement of the spinal elements 
          (facet, pedicle or CV joint fracture or replacement with tumor)</h5>

          <tr>
            <td>Bilateral</td>
            <td>3</td>
          </tr>

          <tr>
            <td>Unilateral</td>
            <td>1</td>
          </tr>

          <tr>
            <td>None of the above</td>
            <td>0</td>
          </tr>

        </tbody>
      </table> */}

      <MediaBox><img width="100%" alt="sins_tavola disegno 1" src={sins_tavoladisegno1} /></MediaBox>

      <p><i>Fourney DR et al. Spine instability neoplastic score: an analysis of reliability and validity from the spine oncology study group. J Clin Oncol. 2011 Aug 1;29(22):3072-7</i></p>
    </div>
  ),
  
  "2.3": (
    <div>
      <p>In case of spinal instability, stabilization (even with minimally invasive techniques) must be undertaken. Decompression and Debulking should be addressed when a symptomatic cord compression occurs or in case of asymptomatic epidural compression from a radio-resistant metastasis. Separation surgery, that is the separation of the tumor from nervous structures, allows to perform Stereotactic radiosurgery (SRS) and Hadrontherapy. 
      When SRS or Hadrontherapy are planned, stabilization may be achieved with carbon fiber reinforced peek instrumentation (even with minally invasive techniques).
      The choise to perfom separation surgery should be guided by the Epidural Spinal Cord Compression Scale that takes in the account the cord compression degree and the sensitivity of the primary tumor to radiotherapy.</p>
      <MediaBox><img alt="escc_tavola_disegno_1" width="100%" src={mininvasiva} /></MediaBox>
      <br></br>
      <MediaBox><img alt="escc_tavola_disegno_1" width="100%" src={escc_tavoladisegno1} /></MediaBox>
      <p><i>Bilsky MH, Laufer I, Fourney DR, Groff M, Schmidt MH, Varga PP, Vrionis FD, Yamada Y, Gerszten PC, Kuklo TR. J Neurosurg Spine. 2010 Sep;13(3):324-8</i></p>
    </div>
  ),

  "2.4": (
    <div>
      <p>If a symtomatic spinal cord compression occurs, the mainstay of treatment in order to restore neurological functions and spinal stability is decompression and stabilization. This procedure is usually associated to the debulking of neoplastic tissue causing cord compression.</p>
      <p>Preoperative Selective Arterial Embolization (SAE) plays an important role reducing intraoperative bleeding, which in same cases can be life-threatening, above all in hypervascularized metastases, such as renal cell carcinoma and thyroid mets.</p>
      <MediaBox><img width="100%" alt="tavola disegno 1" src={emb_tavoladisegno1} /></MediaBox>
      <p><i>Clausen C, Dahl B, Frevert SC, Hansen LV, Nielsen MB, Lönn L. Preoperative embolization in surgical treatment of spinal metastases: single-blind, randomized controlled clinical trial of efficacy in decreasing intraoperative blood loss. J Vasc Inerv Radiol.  2015 Mar;26(3):402-12 </i></p>
      <p>Stabilization is mandatory since that decompression increases spine instability that is associated with the worsening of neurological function.</p>
      <MediaBox><img width="100%" alt="decompressione" src={decompressione01} /></MediaBox>
      <p><i>Patchell RA, Tibbs PA, Regine WF, Payne R, Saris S, Kryscio RJ, Mohiuddin M, Young B. Direct decompressive surgical resection in the treatment of spinal cord compression caused by metastatic cancer: a randomized trial. Lancet. 2005 Aug 20-26;366(9486):643-8</i></p>
    </div>
  ),
  
  "3.1": (
    <div>
      <p>Primary tumor staging is of paramount importance in order to rule out the presence of other disease localization. If the primary tumor is not sensitive to medical therapies and there is a solitary metastasis, aggressive local excision is still recommended, since it is the only available alternative to alter the natural history of disease and to achieve the local tumor control.</p>
    </div>
  ),
  
  "3.2": (
    <div>
      <h5>Debulking (intralesional piecemeal excision)</h5>
      <p>Debulking involves removing as such macroscopic disease as possible in an intralesional piecemeal fashion. It can be achieved by a single approach or through the combination of approaches and, usually, requires more demanding reconstruction for achieving stability. Since this procedure involves working inside the tumor, major bleeding coulf be expected, so preoperative Selective Arterial Embolization (SAE) is highly recommanded.</p>
      <MediaBox><img width="100%" alt="debulking" src={debulking01} /></MediaBox>

      <h5>En Bloc Resection</h5>
      <p>En bloc resection means the removal of tumor with a layer of healthy tissue. In some circumstance it can correspond to the removal of the entire vertebra. It may be achieved by a single approach or through the combination of approaches depending on local tumor extension. This procedure is technically demanding and it associated with high morbidity and should be reserved only in selected patients.</p>
      <MediaBox><img width="100%" alt="en bloc" src={enbloc01} /></MediaBox>
    </div>
  ),
  
  "4.1": (
    <div>
      <p>In patients with multiple secondary localizations, both visceral or skeletal, local treatment is conditioned by their prognosis. Whenever local control of the other metastases is possible, either by surgical or medical means, surgical management of spinal lesions is favored. On the other hand, patients with multiple localizations, not amenable to effective disease control measures and with a poor general prognosis, are not candidates for surgical treatment. In these patients, decision making process requires the opinion of the others medical figures involved in the multidisciplinary approach to cancer patient.</p>
    </div>
  ),
  
  "5.1": (
    <div>
      <p>Patients that are judged to be beyond the reach of surgical and non-operative oncological therapies should be placed under palliative care in order to receive appropriate pain alleviation. This is usually best accomplished under the guidance of specialized units and following euthe local or international guidalines.</p>

      <p><strong>Associazione Italiana Studio del Dolore:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.aisd.it/">www.aisd.it</a>

      <p><strong>Società Italiana di Anestesia Analgesia Rianimazione e Terapia Intensiva:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.siaarti.it/">www.siaarti.it</a>

      <p><strong>Società Italiana di Anestesia Rianimazione e Dolore:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.siared.it/">www.siared.it</a>

      <p><strong>European Society of Regional Anesthesia and Pain Therapy:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.esraeurope.org/">www.esraeurope.org</a>

      <p><strong>American Society of Regional Anesthesia and Pain Medicine:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.asra.com/">www.asra.com</a>
    
      <p>Vertebroplasty is minimally invasive procedure usually performed and local anesthesia that should be considered in patients with considerable mechanical pain due to mild instability and who are not good surgical candidates.</p>

      <MediaBox><img width="100%" alt="VTP" src={VTP01} /></MediaBox>

      <p>If no pain control is achieved with this regime and the patient becomes operable at certain point, surgical management should be considered.</p>
    
    </div>
  ),
  
  "6.1": (
    <div>
      <p>Multidisciplinary approach is the mainstay of management of spinal metastastes. Interaction between Oncologist, Radiotherapist and Surgeon is of paramount importance in the choise of the best treatment tailored on each patient. Case evaluation should consider primary tumor histology, presence of specific receptors, history of prior treatments and their effectiveness.
        If a surgical treatment is indicated, the proper timing must be individuated in relation to medical therapies. Medical therapies, in fact, cannot be delayed since that they represent the main tool to achieve systemic and local control of disease.</p>
      
      <p><strong>Istituto Ortopedico Rizzoli:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.ior.it">www.ior.it</a>

      <p><strong>Associazione Italiana di Oncologia Medica:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.aiom.it">www.aiom.it</a>

      <p><strong>European Society for Medical Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.esmo.org">www.esmo.org</a>

      <p><strong>American Society of Clinical Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.asco.org">www.asco.org</a>

      <p><strong>The Cochrane library:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.thecochranelibrary.com">www.thecochranelibrary.com</a>

      <p><strong>NICE guidance by topic:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.nice.org.uk/guidance">www.nice.org.uk/guidance</a>

      <p><strong>Associazione Italiana di Radioterapia Oncologica:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.radioterapiaitalia.it">www.radioterapiaitalia.it</a>

      <p><strong>European Society for Therapeutic Radiology and Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="https://www.estro.org">www.estro.org</a>

      <p><strong>American Society for Radiation Oncology:</strong></p>
      <a rel="noopener noreferrer" target="_blank" href="http://www.astro.org">www.astro.org</a>
    </div>
  ),
  
  "7.1": (
    <div>
      <p>Combination of chemo, radio, hormone and immuno-therapies should be tailored to the specific patient and histology involved and represents the first-line treatment of spinal metastases. Surgery, in fact, is usually not curative and has an ancillary role. Its aims are: to preserve/recover neurological function; pain relief; to guarantee spine stability and to allow medical therapy to achieve local tumor control.</p>
    </div>
  )

}

const decodeEntitiesHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

const symbol = decodeEntitiesHtml('&plusmn;')
const a = decodeEntitiesHtml('&nbsp;')

export const questions = {
  "0": { 
    question: "Patient operable?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '1.1'},
      {value: 'No', answer: 'No', next: '6.1'}
    ],
    info: modalText["0"],
    pdf: "1"
  },

  "1.1": {
    question: "Symptomatic spinal cord compression?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '1.2'},
      {value: 'No', answer: 'No', next: '2.1'}
    ],
    info: modalText["1.1"],
    pdf: "2"
  },

  "1.2": {
    question: "Chance of neurological recovery?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '2.4'},
      {value: 'No', answer: 'No', next: '2.1'}
    ],
    info: modalText["1.2"],
    pdf: "3"
  },

  // //#region DELETED STEPS
  // DEPRECATED
  // "1.3": {
  //   question: "Increasing neurological damage?",
  //   answers: [
  //     {value: 'Yes', answer: 'Yes', next: '1.4'},
  //     {value: 'No', answer: 'No', next: '2.1'}
  //   ],
  //   info: modalText["1.3"],
  //   pdf: "4"
  // },

  // DEPRECATED
  // "1.4": {
  //   question: "Emergency surgery",
  //   answers: [
  //     {value: 'Yes', answer: 'Yes'},
  //     {value: 'No', answer: 'No'}
  //   ],
  //   info: modalText["1.4"],
  //   pdf: "5"
  // },
  // //#endregion

  "2.1": {
    question: "Sensitivity to non-surgical therapy?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '2.2'},
      {value: 'No', answer: 'No', next: '3.1'}
    ],
    info: modalText["2.1"],
    pdf: "6"
  },

  "2.2": {
    question: "Risk of pathological fracture/instability?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '2.3'},
      {value: 'No', answer: 'No', next: '7.1'}
    ],
    info: modalText["2.2"],
    pdf: "7"
  },

  "2.3": {
    question: `Stabilization ${symbol} decompression`,
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["2.3"],
    pdf: "8"
  },

  "2.4": {
    question: `Decompression and stabilization`,
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["2.4"],
    pdf: "8"
  },

  "3.1": {
    question: "Isolated metastasis?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '3.2'},
      {value: 'No', answer: 'No', next: '4.1'}
    ],
    info: modalText["3.1"],
    pdf: "9"
  },

  "3.2": {
    question: "Excision",
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["3.2"],
    pdf: "l"
  },

  "4.1": {
    question: "Therapeutic possibilities in other localizations?",
    answers: [
      {value: 'Yes', answer: 'Yes', next: '3.2'},
      {value: 'No', answer: 'No', next: '5.1'}
    ],
    info: modalText["4.1"],
    pdf: "m"
  },
  
    "5.1": {
    question: `Pain therapy ${symbol} vertebroplasty`,
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["5.1"],
    pdf: "n"
  },

  "6.1": {
    question: `Sensitivity to non-surgical therapy?`,
    answers: [ 
      {value: 'Yes', answer: 'Yes', next: '7.1'},
      {value: 'No', answer: 'No', next: '5.1'}
    ],
    info: modalText["6.1"],
    pdf: "o"
  },

  "7.1": {
    question: `Non-Surgical therapy`,
    answers: [
      {value: 'Yes', answer: 'Yes'},
      {value: 'No', answer: 'No'}
    ],
    info: modalText["7.1"],
    pdf: "p"
  }
}

// Desktop Pages

const theAlgorithmPage = (
  <div>
    <h4 className="blue-title">Algorithm &amp; Flow Chart</h4>
    <p>The aim of surgical treatment of vertebral metastases are: to preserve or, if possible, recover 
      neurological function; pain relief; guarantee the stability of the spine and local tumor control.
      This algorithm has been designed in order to achieve these objectives, based on the patient’s operability, 
      on the sensitivity of the primary neoplasm to chemotherapy and radiation therapy and on the patient’s 
      neurological status. <br></br>
      It comes from the experience of <strong>Department of Oncological and Degenerative Spine Surgery of Rizzoli Orthopedic 
      Institute</strong> and it was validated by a firstly retrospective and a subsequent multicentric prospective study. 
      This <strong>flow-chart</strong> is not only addressed for spine surgeons but for all actors (oncologists, radiotherapists, 
      anesthesiologists) involved in the multidisciplinary approach to a patient with cancer.
      </p>
    <MediaBox>
      <img src={flowchartImg} width="100%" alt="flow chart pdf" />
    </MediaBox>
    <br></br>
    <a href={flowchartPdf} download="complete-flowchart" rel="noopener noreferrer" target="_blank">
    <Button className="complete-flowchart" waves="light" style={{marginRight: '5px'}} small download="complete-flowchart">
    <Icon left>
    picture_as_pdf
    </Icon>
    Complete Flowchart
    </Button>
    </a>
    <br></br>
    <br></br>
    <hr></hr>
  </div>
)

const aboutPage = (
  <div>
  <h4 className="blue-title">About Us</h4>
  <p>
    <strong>Dr. Alessandro Gasbarrini</strong> is the chief department of Oncologic and Degenerative Spine Surgery
    of <strong>Rizzoli Orthopedic Institute</strong>. His experience in the treatment of primary and metastatic bone
    tumors started at the beginning of Nineties during his residency training under the guidance of
    Prof. Mario Campanacci. In those years he, also, began to focus his interests in spine, which 
    later developed at Maggiore Hospital from 1998 to 2009 at the structure directed by Dr Stefano 
    Boriani becoming a referral center for the treatment primary and metastatic tumors of the spine.<br></br> 
    Since September 2009 he works at Department of Oncologic and Degenerative Spine Surgery of <strong>Rizzoli Orthopedic Institute</strong>. 
    <strong> Dr. Gasbarrini</strong> has contributed and continues to contribute as author or co-author to numerous
    scientific papers published on national and international journals.
    </p>
    <a href="http://www.ior.it/en/curarsi-al-rizzoli/alessandro-gasbarrini-md" rel="noopener noreferrer" target="_blank">
    <Button className="complete-flowchart" waves="light" small >
      Read complete CV of Dr. Alessandro Gasbarrini (italian)
    </Button>
  </a>
  <MediaBox style={{ marginTop: "15px" }}>
    <img src={teamImg} width="100%" alt="the team" />
  </MediaBox>
  <p>
    Dr. Alessandro Gasbarrini together with his team.
  </p>
    </div>
)

const bibliographyPage = (
  <div>
    <h4 className="blue-title">Bibliography</h4>
        {/* <p>Gasbarrini A, Cappuccio M, Mirabile L, Bandiera S, Terzi S, Barbanti Bròdano G, Boriani S. <strong>Spinal metastases: treatment 
    evaluation algorithm. Eur Rev Med Pharmac Sci</strong> (2004)</p>

    <p>Ibrahim A, Crockard A, Antonietti P, Boriani S, Bünger C, Gasbarrini A, Grejs A, Harms J, Kawahara N, Mazel C, Melcher R, 
    Tomita K. <strong>Does spinal surgery improve the quality of life for those with extradural (spinal) osseous metastases? 
    An international multicenter prospective observational study of 223 patients. Invited submission from the Joint Section Meeting 
    on Disorders of the Spine and Peripheral Nerves, March 2007.</strong> J Neurosurg Spine (2008)</p>

    <p>Gasbarrini A, Boriani S. Linee Guida SIOT. <strong>Il trattamento delle metastasi ossee.</strong> (2008)</p>

    <p>Gasbarrini A, Li H, Cappuccio M, Mirabile L, Paderni S, Terzi S, Boriani S. <strong>Efficacy evaluation of a new treatment algorithm 
    for spinal metastases.</strong> Spine (2010)</p> */}

    <p>
        Gasbarrini A, Cappuccio M, Mirabile L, Bandiera S, Terzi S, Barbanti Bròdano G, Boriani S. 
        <strong> Spinal Metastases: treatment evaluation algorithm.</strong> Eur Rev for Med Pharmacol Sci. 2004; 
        8:265- 274.
    </p>

    <p>
        Boriani S, Gasbarrini A. <strong> The Tokuhashi score: Significant predictive value for the life 
        expectancy of patients with breast cancer with spinal metastases.</strong> Point of View. 
        Spine 2005; 30: 2227- 2229.
    </p>

    <p>
        Barbanti Brodano G, Cappuccio M, Gasbarrini A, Bandiera S, De Salvo F, Cosco F, Boriani S. 
        <strong> Vertebroplasty in the treatment of vertebral metastases: clinical cases and review of the 
        literature.</strong> Eur Rev for Med Pharmacol Sci. 2007; 11: 91- 100.
    </p>

    <p>
        Cappuccio M, Gasbarrini A, Van Urk P, Bandiera S, Boriani S. <strong> Spinal metastasis: a 
        retrospctive study validating the treatment algorithm.</strong> Eur Rev Med Pharmacol Sci. 2008; 
        12: 155- 160.
    </p>

    <p>
        Ibrahim A, Crockard A, Antonietti P, Boriani S, Bünger C, Gasbarrini A, Grejs A, Harms J, 
        Kawahara N, Mazel C, Melcher R, Tomita K. <strong> Does spinal surgery improve the quality of life 
        for those with extradural (spinal) osseous metastases? An international multicenter 
        prospective observational study of 223 patients.</strong> J Neurosurg Spine 2008; 8: 271-278. 
    </p>

    <p>
        Li H, Gasbarrini A, Cappuccio M, Terzi S, Paderni S, Mirabile L, Boriani S. 
        <strong> Outcome of excisional surgeries for the patients with spinal metastases.</strong> 
        Eur Spine J 2009; 18: 1423-1430.
    </p>

    <p>
        Gasbarrini A, Li H, Cappuccio M, Mirabile L, Paderni S, Terzi S, Boriani S. 
        <strong> Efficacy evalutation of a new treatment algorithm for spinal metastases.</strong> 
        Spine 2010; 35: 1466-1470.
    </p>

    <p>
        Cappuccio M, Bandiera S, Babbi L, Boriani L, Corgi A, Amendola L, Colangeli S, Terzi S, 
        Gasbarrini A. <strong> Management of bone metastases.</strong> Eur Rev Med Pharmacol Sci. 2010; 14: 407-414.
    </p>

    <p>
        Karadeniz E, Colangeli S, Ghermandi R, Gasbarrini A, Acaroglu E, Boriani S. <strong> Aneurysmal bone 
        cyst-like areas as a sign of metastatic disease in the spinal column.</strong> Acta Orthopaedica et 
        Traumatologica Turcica 2013; 47: 366-369.
    </p>

    <p>
        Piccioli A, Spinelli MS., Forsberg JA, Wedin R, Healey JH, Ippolito V, Daolio PA, 
        Ruggieri P, Maccauro G, Gasbarrini A, Biagini R, Piana R, Fazioli F, Luzzati A, 
        Di Martino A, Nicolosi F, Camnasio F, Rosa MA, Campanacci DA, Denaro V, Capanna R. 
        <strong> How do we estimate survival? External validation of a tool for survival estimation 
        in patients with metastatic bone disease- decision analysis and comparison of three 
        international patient populations.</strong> BMC Cancer 2015; 15:424. 
    </p>

    <p>
        Gasbarrini A, Campos WK, Campanacci L, Boriani S. <strong> Electrochemotherapy to metastatic 
        spinal melanoma: A novel treatment of spinal metastasis?</strong> Spine 2015; 40: E1340-E1346.
    </p>

    <p>
        Pipola V, Terzi S, Tedesco G, Bandiera S, Bròdano GB, Ghermandi R, Evangelisti G, 
        Girolami M, Gasbarrini A. <strong> Metastatic epidural spinal cord compression: does timing of 
        surgery influence the chance of neurological recovery? An observational case-control 
        study.</strong> Support Care Cancer. 2018; 26: 3181- 3186. 
    </p>

    <p>Ghermandi R, Evangelisti G, Girolami M, Pipola V, Bandiera S, Barbanti-Brodano G, Griffoni G, Tedesco G, Terzi S, Gasbarrini 
    A. <strong> Management of Metastases to Spine and Sacrum. Management of Bone Metastases.</strong> Book Chapter (2018)</p>

    <p>Gasbarrini A, Evangelisti G, Ghermandi R, Girolami M, Tedesco G, Pipola V, Boriani S. <strong>Management of Metastatic Spinal Cord
    Compression without Stereotactic Radiotherapy and Targeted Adjuvant Chemotherapy.</strong> Book Chapter</p>

    <p>Gasbarrini A. Linee Guida AIOM. <strong>Emergenze ed Urgenze in Oncologia: Compressione midollare</strong> (2019)</p>

  </div>
)

const disclaimer = (
  <div>
    <h4 className="blue-title">Disclaimer</h4>
    <p>All the information on this website - https://metastases-algorithm.com/webapp - is published in good faith and for general information purpose only. Metastases algorithm does not make any warranties about the completeness, reliability and accuracy of these informations. Any action you take upon the information you find on this website, is strictly at your own risk. metastases algorithm will not be liable for any losses and/or damages in connection with the use of our website. </p>
    <p>Please be also aware that when you leave our website, other sites may have different privacy policies and terms which are beyond our control. Please be sure to check the Privacy Policies of these sites as well as their "Terms of Service" before engaging in any business or uploading any information.</p>
    <h5>Consent</h5>
    <p>By using our website, you hereby consent to our disclaimer and agree to its terms.</p>
    <h5>Update</h5>
    <p>Should we update, amend or make any changes to this document, those changes will be prominently posted here.</p>  
  </div>
)


const howToQA = (
  <div>
    <h4 className="blue-title">How To Q&A</h4>
    <p><strong><i>A. How to add the "metastases-algorithm.com" icon to your mobile phone's home screen? *</i></strong></p>
    <p><strong>Android</strong></p>
    <ol>
      <li>Launch your browser (e.g. Chrome) and navigate to "www.metastases-algorithm.com".</li>
      <li>Tap on the icon "hamburger menu", i.e. the three dots icon which is usually in the right corner at the top of your screen.</li>
      <li>It will pop-up a menu. Select and tap the "Add to Home screen" menu item.</li>
      <li>It will open a window. Confirm (by clicking add) the suggested name for the shortcut "Spinal Metastases Algorithm", or enter one you like better.</li>
      <li>This will open another window. You can tap "Add automatically", but it might be more convenient to place it manually, 
      by touching and holding the icon. It permits to drag the icon the the desired position among your aother icons on one of your home-screens.</li>
      <li>You're now ready to visit the webapp by clicking next time on the new shortcut. *</li>
    </ol>

    <p><strong>iOS and iPadOS</strong></p>
    <ol>
      <li>Launch your Safari browser and navigate to "www.metastases-algorithm.com".</li>
      <li>Tap the Bookmark icon at the bottom of the screen (the upward-pointing arrow).</li>
      <li>Tap "Add to Home Screen".</li>
      <li>Confirm the suggested name for the shortcut "Spinal Metastases Algorithm", or enter one you like better.</li>
      <li>Tap "Add to save the new shortcut icon to the home screen".</li>
      <li>You're now ready to visit the webapp by clicking next time on the new shortcut. *</li>
    </ol>
    <p>
      Read also:<br></br>
      <a rel="noopener noreferrer" target="_blank" href="https://www.lifewire.com/home-screen-icons-in-safari-for-iphone-and-amp-ipod-touch-4103654">Make a Safari Shortcut on Your iPhone, iPad, or iPod touch Home Screen</a>
    </p>
    <p><i>* Once you've created a shortcut to the url of the algorithm, it can be used even without internet connection, but flow-files will 
    be downloaded once you are online again.</i></p>
    <p><strong><i>B. Downloading files on the latest iOS, or iPadOS</i></strong></p>
    <p>It is useful to know how to configure download folders on your Iphone or Ipad and where files are downloaded to by default. 
    Maybe it's useful to read the support page in the apple website:</p>
    <a rel="noopener noreferrer" target="_blank" href="https://support.apple.com/en-us/HT206481"> Use the Files app on your iPhone, iPad, or iPod touch</a>

    
    <p>or this article:</p>
    <a rel="noopener noreferrer" target="_blank" href="https://www.lifewire.com/how-to-find-downloads-on-phone-4587236">Where Are The Downloads On My Phone?</a>

  </div>
)

const creditsPage = (
  <div>
    <h4 className="blue-title">Credits</h4>
    <h5>Concept and design</h5>
      <p>Alessandro Gasbarrini, MD
      <br></br>
      Department of Oncological and Degenerative Spine Surgery,
      <br></br>
      Rizzoli Institute, Bologna, Italy
      </p>

      <a rel="noopener noreferrer" target="_blank" href="http://www.ior.it/curarsi-al-rizzoli/chirurgia-vertebrale-indirizzo-oncologico-e-degenerativo">
      <img height="70px" src={LogoIOR} alt="ior logo" />
      </a>
      <hr></hr>
      <h5>Creative design &amp; technical development</h5>
      <p><strong>Web and More srl, Bologna</strong></p>
      <a rel="noreferrer noopener" target="_blank" href="https://www.webandmore.it">
      <img height="70px" src={LogoWEM} alt="web and more logo" /></a>
      <p>
          Marco Nesi
          <br></br>
          Eric Vleugels
          <br></br>
          Thomas Brandoli                    
      </p>
      <hr></hr>
      <h5>Fondazione Probone Italia</h5>
      <a rel="noreferrer noopener" target="_blank" href="https://www.probone.org">
      <img height="70px" src={LogoProbone} alt="probone logo" /></a>
      <hr></hr>
      <p>The also are grateful to the following people for their contribution:</p>
      <ul>
        <li>Michele Cappuccio, MD</li>
        <li>Stefano Boriani, MD</li>
        <li>Stefano Bandiera, MD</li>
        <li>Giovanni Barbanti Bròdano, MD</li>
        <li>Silvia Terzi, MD</li>                        
        <li>Riccardo Ghermandi, MD</li>
        <li>Giuseppe Tedesco, MD</li>
        <li>Gisberto Evangelisti, MD</li>
        <li>Carlotta Cavallari, MD</li>
        <li>Marco Girolami, MD</li>
        <li>Valerio Pipola, MD</li>
        <li>Carlo Piovani</li>                        
        <li>Cristiana Griffoni</li>
        <li>Geraldine Venturoli</li>                    
      </ul>          
  </div>
)

export default class MainPage extends Component {

  state = {
    isChecked: false,
    haveConsent: false,
    buttonConsent: false,
    rightContent: theAlgorithmPage
  }
  
  handleCheck = () => {
    const button_c = document.getElementById("button-consent");
    if(this.state.isChecked === false) {
        button_c.disabled = false;
        this.setState({isChecked: true})
    }

    else if(this.state.isChecked === true) {
        button_c.disabled = true;
        this.setState({isChecked: false})
    }
  }

  handleButton = () => {
    this.setState({buttonConsent: true})

    if(!this.state.buttonConsent)
    {
      this.setState({haveConsent: true})
    }
  }

  render() {

    let chatSection;
    const { haveConsent } = this.state;
    const { isChecked } = this.state;
    const { rightContent } = this.state;

  // #region logo animation

    const pulse = keyframes`
      0% {
      transform: scale(1.3);
      transform-origin: 50%;
      transform-box: fill-box;
      }

      100% {
      transform: scale(1);
      transform-origin: 50%;
      transform-box: fill-box;
      }
      `;

    let AnimatedSVG;
    
    if(!isEdge) {

    AnimatedSVG = styled(ImportedComponent)`
      .cuore {
          animation: ${pulse} infinite alternate 1s linear;
      };
      .cuore_2 {
          animation: ${pulse} infinite alternate 1s linear;
      };
      `;
    } else { return null; }

// #endregion


    if(haveConsent && isChecked)
    {
      chatSection = (<QuestionFlow questions={questions} initialQuestion="0"></QuestionFlow> )
    } else {
      chatSection = (<Consent value={this.state.buttonConsent} buttonClick={this.handleButton} event={this.handleCheck} />)
    }

    const handleDesktopRightContent = (e) => {

      if(e.target.dataset.page === 'thealgorithm') {
        this.setState({rightContent: theAlgorithmPage})
      }

      else if(e.target.dataset.page === 'about')
        this.setState({rightContent: aboutPage})
      
      else if (e.target.dataset.page === 'bibliography')
        this.setState({rightContent: bibliographyPage})

      else if (e.target.dataset.page === 'disclaimer')
        this.setState({rightContent: disclaimer})

      else if (e.target.dataset.page === 'how-to-qa')
        this.setState({rightContent: howToQA})

      else if (e.target.dataset.page === 'credits')
      this.setState({rightContent: creditsPage})    
    }

    return (

      <div className="mainpage-desktop">
        <Row>
          <Col l={12} m={12}>
            <header>
              <AnimatedSVG className="svg-desktop" />
            </header>
          </Col>
        </Row>
        <Row>
          <Col l={4} m={6} s={12}>
              {chatSection}
          </Col>
          
          <Col l={8} m={6} s={12} className="right-section">
            <div>
              <NavBarMenu position="left" selectPage={handleDesktopRightContent}></NavBarMenu>
              <InfoWindow content={rightContent}></InfoWindow>
            </div>
              <Footer></Footer>
          </Col> 
        </Row>
    </div>
    );
  }
}

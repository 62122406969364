import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer>
            <p className="footer-text">
            <strong>Department of Oncological and Degenerative Spine Surgery, IRCCS</strong><br/>
            <strong>Istituto Ortopedico Rizzoli, Bologna, Italy </strong>
            <strong>Registered office, Administration, Codivilla-Putti Research Centre, Outpatient clinic: </strong>via di Barbiano, 1/10 - 40136 Bologna
            <strong> Hospital:</strong> via G.C.Pupilli, 1 - 40136 <strong>Tax code number and VAT number:</strong> 00302030374 - <strong>E-Mail: </strong>
            info_urp@ior.it - <strong>Certified Mail: </strong>urp@pec.ior.it
            </p>
            </footer>
        </div>
    )
}

export default Footer

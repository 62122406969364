import React, { Component } from 'react'
import { Button } from 'react-materialize';
import './Consent.css'
import 'materialize-css/dist/css/materialize.min.css';
import Checkbox from 'react-materialize/lib/Checkbox';

export default class Consent extends Component {
    
    handleCheck = (e) => {
        const button_c = document.getElementById("button-consent");
        if(this.state.isCheck === false) {
            button_c.disabled = false;
            this.setState({isCheck: true})
        }

        else if(this.state.isCheck === true) {
            button_c.disabled = true;
            this.setState({isCheck: false})
        }
    }

    componentDidMount() {
        const button_c = document.getElementById("button-consent");
        button_c.disabled = true;
    }

    render() {

        return (
            <div className="consent">
                <h3>We value your privacy</h3>
            <p className="privacy-text">We hereby state that when using this app no personal data 
            whatsoever is processed by us or third parties, we only collect statistical information on 
            the use of the app without tracing your IP.</p>
            {/* <Modal header="Modal Header" fixedFooter trigger={<Button />}>
            </Modal> */}
            <Checkbox filledIn value="" label="" onChange={this.props.event} />
            <Button className="transparent" waves="light" value={this.props.value} onClick={this.props.buttonClick} id="button-consent">okay, start</Button>
        </div>
        )
    }
}